import React, { useEffect, useState } from "react";
import Header from "./Header";
// import { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import ReactLoading from 'react-loading';
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function EditProduct({ ProductData, setProductData }) {
  const navigate = useNavigate();
  const [productCategories, setproductCategories] = useState([]);
  const [CategoryModel, setCategoryModel] = useState(false);
  const [CategoryName, setCategoryName] = useState('');
  const [loading, setloading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...ProductData, [name]: value });
  };

  const handleSubmit = async () => {
    let res = await axios.post(
      "https://ecombackend.glitch.me/UpdateProduct",
      ProductData
    );
    if (res.data) { 
      toast.success(res.data.message);
      setProductData(null);
      navigate("/admin/Products");
    }
  };
  const getCategory = async () => {
    await axios
      .get("https://ecombackend.glitch.me/categories")
      .then((res) => {
        const { categories } = res.data;
        setproductCategories(categories);
      });
  };
  useEffect(() => {
    getCategory();
  }, []);

  const handleAddCategory = async () => {
    setloading(true);
    try {
      if(CategoryName === ''){
        toast.error("Select or Create Category")
        setloading(false)
        return
      }
      let res = await axios.post(
        "https://ecombackend.glitch.me/AddCategory",
        { CategoryName }
      );

      if (res.data) {
        setloading(false);
        toast.success(res.data.message);
        setCategoryModel(false);
      }
    } catch (err) {
      toast.error("Try again");
      setloading(false);
      setCategoryModel(false);
    }
  }

  return (
    <div className="main">
      <Header />
      <div className="form-field">
        <Typography
          variant="h5"
          sx={{ width: "100%", mb: 4, display: "flex", alignItems: "center" }}
        >
          <AddCircleOutlineTwoToneIcon
            sx={{ color: "#6945ff", mr: 1 }}
          ></AddCircleOutlineTwoToneIcon>{" "}
          Edit Product:
        </Typography>
        {loading ? <center><ReactLoading type='spin' color='black' height={200} width={100} /></center> :(
        <form>
          <div className="input-field">
            <label className="label-text">Product Name:</label>
            <br />
            <input
              type="text"
              value={ProductData.ProductName}
              name="ProductName"
              onChange={handleChange}
              className="input-box"
            ></input>
          </div>
          <div className="input-field">
            <label className="label-text">Product MRP:</label>
            <br />
            <input
              type="tel"
              value={ProductData.ProductMRP}
              name="ProductMRP"
              onChange={handleChange}
              className="input-box"
            ></input>
          </div>
          <div className="input-field">
            <label className="label-text">Product Price:</label>
            <br />
            <input
              type="tel"
              value={ProductData.ProductPrice}
              name="ProductPrice"
              onChange={handleChange}
              className="input-box"
            ></input>
          </div>
          <div className="input-field">
            <label className="label-text">Product Short Description:</label>
            <br />
            <input
              type="text"
              value={ProductData.ProductShortDesc}
              name="ProductShortDesc"
              onChange={handleChange}
              className="input-box"
            ></input>
          </div>
          <div className="input-field">
            <label className="label-text">Product Long Description:</label>
            <br />
            <input
              type="text"
              value={ProductData.ProductLongDesc}
              name="ProductLongDesc"
              onChange={handleChange}
              className="input-box"
            ></input>
          </div>
          <div className="input-field">
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', padding: 0, margin: 0, height: 'auto' }}>
                <label className="label-text">Product Category:</label>
                <label className="label-right" onClick={() => setCategoryModel(true)} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Create Category</label>
              </div>
              <Select
                value={ProductData.ProductCategory}
                name="ProductCategory"
                onChange={handleChange}
                style={{ width: '80%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productCategories.map((category) => (
                  <MenuItem key={category._id} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>

            </div>

          
          <div className="input-field">
            <label className="label-text">Product Brand:</label>
            <br />
            <input
              type="text"
              value={ProductData.ProductBrand}
              name="ProductBrand"
              onChange={handleChange}
              className="input-box"
            ></input>
          </div>
          <div className="input-field">
            <label className="label-text">Product Color:</label>
            <br />
            <input
              type="text"
              value={ProductData.ProductColor}
              name="ProductColor"
              onChange={handleChange}
              className="input-box"
            ></input>
          </div>
          <div className="input-field">
            <label className="label-text">Product Size:</label>
            <br />
            <input
              type="text"
              value={ProductData.ProductSize}
              name="ProductSize"
              onChange={handleChange}
              className="input-box"
            ></input>
          </div>
          <div className="input-field">
            <label className="label-text">Product Quantity:</label>
            <br />
            <input
              type="text"
              value={ProductData.ProductQuantity}
              name="ProductQuantity"
              onChange={handleChange}
              className="input-box"
            ></input>
          </div>
          <div className="input-field">
              <label className="label-text">Product Discount:</label>
              <br />
              <input
                type="text"
                value={ProductData.ProductDiscount}
                name="ProductDiscount"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>            
            <div className="input-field">
              <label className="label-text">Product GST:</label>
              <br />
              <input
                type="text"
                value={ProductData.ProductGST}
                name="ProductGST"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product HSN:</label>
              <br />
              <input
                type="text"
                value={ProductData.ProductHSN}
                name="ProductHSN"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
          <div className="input-field">
            <input
              type="button"
              value="Update Product"
              onClick={handleSubmit}
              className="register-button"
            />
          </div>
        </form>)}
      </div>
      <Dialog
          open={CategoryModel}
          onClose={() => setCategoryModel(false)}
        >
          <Box sx={{ width: ["auto", 380], height: ["auto", 250] }}>
            <DialogTitle
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Create Category
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setCategoryModel(false)}
              >
                X
              </span>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1.5, width: "90%" },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: ["auto"],
                }}
              >
                <TextField
                  type="text"
                  name="CategoryName"
                  value={CategoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  variant="standard"
                  label="New Category Name"
                  fullWidth
                />
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleAddCategory}
                  color="primary"
                  sx={{ width: "90%", height: 40 }}
                >
                  Create Category
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>

      <ToastContainer/>
    </div>
  );
}
