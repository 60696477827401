import React, { useEffect, useState } from "react";
import {
  Box,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import DeleteIcon from "@mui/icons-material/Delete";

import axios from "axios";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Category() {
    const [Data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteitem, setdeleteitem] = useState("");    
    const checklogin = localStorage.getItem("login");
    const navigate = useNavigate();
    
    if (!checklogin) {
      navigate("/");
    }
      const getData = async () => {
        await axios
          .get("https://ecombackend.glitch.me/categories")
          .then((res) => {
            setData(res.data);
          });
      };
    
      useEffect(() => {
        getData();
      }, []);

          
      let i = 0;
      // ---delete Code----
      const handleclose = () => {
        setOpen(false);
      };
    
      const handleDelete = (id) => {
        setOpen(true);
        setdeleteitem(id);
      };
      const confirmDelete = async (id) => {
        await axios
          .delete(`https://ecombackend.glitch.me/deleteCategory/${id}`)
          .then((res) => {
            toast.success(res.data.message);
            getData();
            setOpen(false);
          })
          .catch((err) => {
            setOpen(false);
          });
      };
      const theme = useTheme();
      const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
    <>
          <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          bgcolor: "#F5F5F5",
          height: "auto",
          marginLeft: isSmallerScreen ? 0 : "300px"
        }}
      >
        <Toolbar />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h4"
            sx={{
              fontSize: isSmallerScreen?  "18px":"20px",
              color: "#6945FF",
              textAlign: "center",
            }}
          >
            Category Management
          </Typography>
        </Box>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            {/* // first Row */}
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={18}
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "30px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  Category
                </TableCell>
              </TableRow>
            </TableHead>

            {/* // Thrid row */}
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Id
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Category Image
                </TableCell>   
                 <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Category Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                  }}
                >
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>

            {Data?.toReversed().map((user) => {
              i = i + 1;
              return (
                <TableBody key={user._id}>
                  <TableRow>
                    <TableCell align="center" sx={{ width: "150px" }}>
                      {i}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={user.image}
                        alt={user.image}
                        width="50px"
                      />
                    </TableCell>

                    <TableCell align="center" sx={{width:'600px'}}>
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "600px",
                          textOverflow: "ellipsis",
                          textAlign:'center'
                        }}
                      >
                        {user.CategoryName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        className="DeleteIcon"
                        onClick={() => handleDelete(user.id)}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>

        <Dialog
          open={open}
          onClose={handleclose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure ?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your will not be able to recover this imaginary file!
            </DialogContentText>

            <DialogActions>
              <Button onClick={() => setOpen(false)}>cancle</Button>
              <Button onClick={() => confirmDelete(deleteitem)}>
                Yes, delete it!
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Box>
      <ToastContainer />
    </>
  )
}
