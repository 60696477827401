import React, { useEffect, useState } from "react";
import Header from "./Header";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import ReactLoading from 'react-loading';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function AddProduct() {
  const [product, addproduct] = useState({
    ProductName: "",
    ProductMRP: "",
    ProductPrice: "",
    ProductMainImgUrl: "",
    ProductShortDesc: "",
    ProductLongDesc: "",
    ProductColor: "",
    ProductCategory: "",
    ProductSubCategory:"",
    ProductBrand: "",
    ProductSize: "",
    ProductQuantity: "",
    ProductDiscount: "",
    ProductGST: "",
    ProductHSN: ""
  });
  const [loading, setloading] = useState(false);
  const [productCategories, setproductCategories] = useState([]);
  const [productSubCategories, setproductSubCategories] = useState([]);
  const [CategoryModel, setCategoryModel] = useState(false);
  const [SubCategoryModel, setSubCategoryModel] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [subCategoryData, setsubCategoryData] = useState(null);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    addproduct({ ...product, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    addproduct({ ...product, ProductMainImgUrl: file });
  };

  const getCategory = async () => {
    await axios
      .get("https://ecombackend.glitch.me/categories")
      .then((res) => {
        setproductCategories(res.data);
      });
  };
  const getSubCategory = async () => {
    await axios
      .get("https://ecombackend.glitch.me/subcategories")
      .then((res) => {
        setproductSubCategories(res.data);
      });
  };

  useEffect(() => {
    getCategory();
    getSubCategory();
  }, []);
  const handleCategoryFileChange = (e) => {
    const file = e.target.files[0];
    setCategoryData({ ...categoryData, image: file });
  };  
  const handleSubCategoryFileChange = (e) => {
    const file = e.target.files[0];
    setsubCategoryData({ ...subCategoryData, image: file });
  };
  
  const handleAddCategory = async () => {
    setloading(true);
    try {
      if (!categoryData.name) {
        toast.error("Select or Create Category");
        setloading(false);
        return;
      }
  
      // Create FormData object
      const formData = new FormData();
      formData.append("CategoryName", categoryData.name);
      formData.append("image", categoryData.image); // Add the file here
  
      // Add any additional data if needed
      if (categoryData.description) {
        formData.append("description", categoryData.description);
      }
  
      let res = await axios.post(
        "https://ecombackend.glitch.me/categories",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Specify content type for file uploads
          },
        }
      );
  
      if (res.data) {
        setloading(false);
        toast.success(res.data.message);
        setCategoryModel(false);
      }
    } catch (err) {
      toast.error("Try again");
      setloading(false);
      setCategoryModel(false);
    }
  };
  
  const handleSubAddCategory = async () => {
    setloading(true);
    try {
      if (!subCategoryData.name) {
        toast.error("Select or Create Sub Category");
        setloading(false);
        return;
      }
  
      // Create FormData object
      const formData = new FormData();
      formData.append("CategoryId", subCategoryData.CategoryId);
      formData.append("SubCategoryName", subCategoryData.name);
      formData.append("image", subCategoryData.image); 
  
      // Add any additional data if needed
      if (subCategoryData.description) {
        formData.append("description", subCategoryData.description);
      }
  
      let res = await axios.post(
        "https://ecombackend.glitch.me/subcategories",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Specify content type for file uploads
          },
        }
      );
  
      if (res.data) {
        setloading(false);
        toast.success(res.data.message);
        setSubCategoryModel(false);
      }
    } catch (err) {
      toast.error("Try again");
      setloading(false);
      setSubCategoryModel(false);
    }
  };
  

  const validateForm = () => {
    if (!product.ProductPrice) {
      toast.error('Enter Product Price')
      return true;
    }
    if (!product.ProductName) {
      toast.error('Enter Product Name')
      return true;
    }
    if (!product.ProductMRP) {
      toast.error('Enter Product Name')
      return true;
    }
    if (!product.ProductCategory && product.ProductCategory === "") {
      toast.error('Select Product Category')
      return true;
    }
    if (!product.ProductSubCategory && product.ProductSubCategory === "") {
      toast.error('Select Product Sub Category')
      return true;
    }

    return false;
  }
  useEffect(() => {
    if (product.ProductCategory) {
      const filtered = productSubCategories.filter(
        (subCategory) => subCategory.CategoryId._id === product.ProductCategory
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories([]);
    }
  }, [product.ProductCategory, productSubCategories]);
  const handleSubmit = async () => {
    setloading(true);
    if (validateForm()) {
      setloading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("ProductName", product.ProductName);
      formData.append("ProductMRP", product.ProductMRP);
      formData.append("ProductPrice", product.ProductPrice);
      formData.append("ProductMainImgUrl", product.ProductMainImgUrl);
      formData.append("ProductShortDesc", product.ProductShortDesc);
      formData.append("ProductLongDesc", product.ProductLongDesc);
      formData.append("ProductColor", product.ProductColor);
      formData.append("ProductCategory", product.ProductCategory);
      formData.append("ProductSubCategory", product.ProductSubCategory);
      formData.append("ProductBrand", product.ProductBrand);
      formData.append("ProductSize", product.ProductSize);
      formData.append("ProductQuantity", product.ProductQuantity);
      formData.append("ProductDiscount", product.ProductDiscount);
      formData.append("ProductGST", product.ProductGST);
      formData.append("ProductHSN", product.ProductHSN);

      let res = await axios.post(
        "https://ecombackend.glitch.me/AddProduct",
        formData
      );

      if (res.data) {
        setloading(false);
        toast.success(res.data.message);
        addproduct({
          ProductName: "",
          ProductMRP: "",
          ProductPrice: "",
          ProductMainImgUrl: "",
          ProductShortDesc: "",
          ProductLongDesc: "",
          ProductColor: "",
          ProductCategory: "",
          ProductSubCategory: "",
          ProductBrand: "",
          ProductSize: "",
          ProductQuantity: "",
          ProductDiscount: "",
          ProductGST: "",
          ProductHSN: ""
        });
      }
    } catch (err) {
      toast.error("Try again");
      setloading(false);
    }
  };
  return (
    <>
      <Header />
      <div className="form-field">
        <Typography
          variant="h5"
          sx={{ width: "100%", mb: 4, display: "flex", alignItems: "center" }}
        >
          <AddCircleOutlineTwoToneIcon
            sx={{ color: "#6945ff", mr: 1 }}
          ></AddCircleOutlineTwoToneIcon>
          Add a Product:
        </Typography>
        {loading ? <center><ReactLoading type='spin' color='black' height={200} width={100} /></center> :
          <form>
            <div className="input-field">
              <label className="label-text">Product Name:</label>
              <br />
              <input
                type="text"
                value={product.ProductName}
                name="ProductName"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product MRP:</label>
              <br />
              <input
                type="tel"
                value={product.ProductMRP}
                name="ProductMRP"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Price:</label>
              <br />
              <input
                type="tel"
                value={product.ProductPrice}
                name="ProductPrice"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Short Description:</label>
              <br />
              <input
                type="text"
                value={product.ProductShortDesc}
                name="ProductShortDesc"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Long Description:</label>
              <br />
              <input
                type="text"
                value={product.ProductLongDesc}
                name="ProductLongDesc"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Main Image URL:</label>
              <br />
              <input
                type="file"
                accept="image/*"
                name="ProductMainImgUrl"
                onChange={handleFileChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', padding: 0, margin: 0, height: 'auto' }}>
                <label className="label-text">Product Category:</label>
                <label className="label-right" onClick={() => setCategoryModel(true)} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Create Category</label>
              </div>
              <Select
                value={product.ProductCategory}
                name="ProductCategory"
                onChange={handleChange}
                style={{ width: '80%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productCategories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.CategoryName}
                  </MenuItem>
                ))}
              </Select>

            </div>
            <div className="input-field">
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', padding: 0, margin: 0, height: 'auto' }}>
                <label className="label-text">Product Sub Category:</label>
                <label className="label-right" onClick={()=>setSubCategoryModel(true)} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Create Sub Category</label>
              </div>
              <Select
                value={product.ProductSubCategory}
                name="ProductSubCategory"
                onChange={handleChange}
                style={{ width: '80%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {filteredSubCategories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.SubCategoryName}
                  </MenuItem>
                ))}
              </Select>

            </div>

            <div className="input-field">
              <label className="label-text">Product Brand:</label>
              <br />
              <input
                type="text"
                value={product.ProductBrand}
                name="ProductBrand"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Color:</label>
              <br />
              <input
                type="text"
                value={product.ProductColor}
                name="ProductColor"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Size:</label>
              <br />
              <input
                type="text"
                value={product.ProductSize}
                name="ProductSize"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Quantity:</label>
              <br />
              <input
                type="text"
                value={product.ProductQuantity}
                name="ProductQuantity"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product Discount:</label>
              <br />
              <input
                type="text"
                value={product.ProductDiscount}
                name="ProductDiscount"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product GST:</label>
              <br />
              <input
                type="text"
                value={product.ProductGST}
                name="ProductGST"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <label className="label-text">Product HSN:</label>
              <br />
              <input
                type="text"
                value={product.ProductHSN}
                name="ProductHSN"
                onChange={handleChange}
                className="input-box"
              ></input>
            </div>
            <div className="input-field">
              <input
                type="button"
                value="Add Product"
                onClick={handleSubmit}
                className="register-button"
              />
            </div>
          </form>
        }
        <Dialog
          open={CategoryModel}
          onClose={() => setCategoryModel(false)}
        >
          <Box sx={{ width: ["auto", 380], height: ["auto", 250] }}>
            <DialogTitle
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Create Category
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setCategoryModel(false)}
              >
                X
              </span>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1.5, width: "90%" },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: ["auto"],
                }}
              >
                <TextField
                  type="text"
                  name="name"
                  value={categoryData?.name}
                  onChange={(e) => setCategoryData({ ...categoryData, name: e.target.value })}
                  variant="standard"
                  label="New Category Name"
                  fullWidth
                />
                <div className="input-field">
                  <label className="label-text">Category Image URL:</label>
                  <br />
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={handleCategoryFileChange}
                    className="input-box"
                  ></input>
                </div>
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleAddCategory}
                  color="primary"
                  sx={{ width: "90%", height: 40 }}
                >
                  Create Category
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>


        <Dialog
          open={SubCategoryModel}
          onClose={() => setSubCategoryModel(false)}
        >
          <Box sx={{ width: ["auto", 380], height: ["auto", 250] }}>
            <DialogTitle
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Create Sub Category
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setSubCategoryModel(false)}
              >
                X
              </span>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1.5, width: "90%" },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: ["auto"],
                }}
              >
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', padding: 0, margin: 0, height: 'auto' }}>
                <label className="label-text">Product Category:</label>
              </div>
              <Select
                value={subCategoryData?.CategoryId}
                name="CategoryId"
                onChange={(e) => setsubCategoryData({ ...subCategoryData, CategoryId: e.target.value })}
                style={{ width: '80%' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productCategories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.CategoryName}
                  </MenuItem>
                ))}
              </Select>

                <TextField
                  type="text"
                  name="name"
                  value={categoryData?.name}
                  onChange={(e) => setsubCategoryData({ ...subCategoryData, name: e.target.value })}
                  variant="standard"
                  label="New Sub Category Name"
                  fullWidth
                />
                <div className="input-field">
                  <label className="label-text">Sub Category Image URL:</label>
                  <br />
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={handleSubCategoryFileChange}
                    className="input-box"
                  ></input>
                </div>
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleSubAddCategory}
                  color="primary"
                  sx={{ width: "90%", height: 40 }}
                >
                  Create Sub Category
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>

        <ToastContainer />
      </div>
    </>
  );
}
