import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import TocIcon from "@mui/icons-material/Toc";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Muinavbar({ cartDataLength }) {
  const navigate = useNavigate();
  const [search, setsearch] = useState("");
  const [isotp,setIsOtp] = useState(false);
  const [otp,setOtp] = useState(0);
  const location = useLocation();
  const [register, setregister] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Mobile: 0,
    Gender: "",
    Password: "",
    Address: "",
    City: "",
    State: "",
    PinCode: "",
    ReferenceBy: "",
  });


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const popupParam = searchParams.get('popup');
    const popupsignup = searchParams.get('popupsignup')
    const refquery = searchParams.get('ref');
    if (popupParam === 'true') {
      setopenPopup(true);
    }
    if(popupsignup === 'true'){
      setsignup(true);
    }    
    if(refquery !== null){
      setregister({...register,ReferenceBy:refquery});
    }
  }, [location.search,register]);

  const handleSearchSubmit = () => {
    navigate(`/Filter/${search}`);
  };
  const gotocart = () => {
    navigate("/cart");
  };
  const gotoprofile = () => {
    navigate("/profile");
  };
  const gotoorders = () => {
    navigate("/orders");
  };
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setregister({ ...register, [name]: value });
    if(name === "Email"){
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        setEmailError('Invalid email address');
      } else {
        setEmailError('');
      }
    }

    if(name === "Mobile"){
      if (!/^\d{10}$/.test(value)) {
        setMobileError('Invalid mobile number');
      } else {
        setMobileError('');
      }
    }
  };

  const [isDrawerOpen, SetIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setopenPopup] = useState(false);
  const [signup, setsignup] = useState(false);
  const [LoginStatus, setLoginStatus] = useState(false);
  const [Username, setUsername] = useState("");
  const [Data, setData] = useState([]);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [MenuOpenclose, setMenuOpenclose] = useState(null);
  const menuopenclose = Boolean(MenuOpenclose);
  const handleClose = () => {
    setMenuOpenclose(null);
  };
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleClick = () => {
    setopenPopup(!openPopup);
    setsignup(!signup);
  };
  const [data1, setData1] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  useEffect(() => {
    fetch('/states.json')
      .then(response => response.json())
      .then(data => {
        setData1(data.states);
        setStates(data.states.map(state => state.state));
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  
  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);
    const selectedStateData = data1.find(item => item.state === state);
    setCities(selectedStateData ? selectedStateData.districts : []);
    setregister(prevState => ({
      ...prevState,
      State: state,
      City: ''
    }));
  };

  const handleCityChange = (event) => {
    const city = event.target.value;
    setregister(prevState => ({
      ...prevState,
      City: city
    }));
  };

  const getData = async () => {
    const check = localStorage.getItem("LoginUser");
    setLoginStatus(check);
    const un = localStorage.getItem("Username");
    setUsername(un);
    try {
      await axios
        .get("https://ecombackend.glitch.me/categories")
        .then((res) => {
          setData(res.data);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const [randomOTP,setrandomOTP] = useState(0);
  const VerifyFormAndSendOTP = async()=>{
    if(!register.Mobile){
      return alert('Mobile number is required');
    }
    const ranOTP  =  Math.floor(Math.random() * (999999-100000 + 1)) + 100000;
    setrandomOTP(ranOTP);
    try{
       await axios.post('https://ecombackend.glitch.me/sendOTP',{number:register.Mobile,otp:ranOTP});
        toast.success("OTP Sent Successfully");
        setIsOtp(true);
  }catch{
    toast.error("Something won't wrong")
  }
  }
  const handleSubmit = async () => {
    if(randomOTP !== Number(otp)){
      toast.error("OTP Incorrect");
      return;
    }
    try {
      await axios
        .post("https://ecombackend.glitch.me/Register", register)
        .then((res) => {
          toast.success(res.data.message, { autoClose: 5000 });
          const { data } = res.data;
          localStorage.setItem("LoginUser", true);
          localStorage.setItem("UserId", data._id);
          localStorage.setItem("Username", data.FirstName);
          getData();
          setIsOtp(false);
          setopenPopup(false);
          handleMenuClose(null);
          setsignup(false);
          setrandomOTP(0);
          setregister({
            FirstName: "",
            LastName: "",
            Email: "",
            Mobile: 0,
            Gender: "",
            Password: "",
            Address: "",
            City: "",
            State: "",
            PinCode: "",
            ReferenceBy: "",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  const [login, setLogin] = useState({
    Email: "",
    Password: "",
  });

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };
  const handleCloseLogin = ()=>{
    setopenPopup(false);
    handleMenuClose(null);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('popup', 'false');
    navigate({ search: searchParams.toString() });
  }
  const handleCloseRegister = ()=>{
    setsignup(false)
    handleMenuClose(null);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('popupsignup', 'false');
    navigate({ search: searchParams.toString() });
  }
  const handleLoginSubmit = async () => {
    if (login.Email === "" && login.Password === "") {
      toast.error("Enter Username and Password");
      return;
    }
    try {
      await axios
        .post("https://ecombackend.glitch.me/Login", login)
        .then((res) => {
          const { data } = res.data;
          if (res.data.success) {
            toast.success(res.data.message, { autoClose: 5000 });
            localStorage.setItem("LoginUser", true);
            localStorage.setItem("UserId", data._id);
            localStorage.setItem("Username", data.FirstName);
            getData();
            setopenPopup(false);
            handleMenuClose(null);
          } else {
            toast.error(res.data.message, { autoClose: 5000 });
          }
          setLogin({
            Email: "",
            Password: "",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("LoginUser");
    localStorage.removeItem("UserId");
    localStorage.removeItem("Username");
    localStorage.removeItem("cartdata");
    navigate("/");
    getData();
    handleMenuClose(null);
    toast.success("Logout Successfully", { autoClose: 5000 });
  };

  const [cdl, setcdl] = useState(0);

  const getcartlen = () => {
    const cartData = JSON.parse(localStorage.getItem("cartdata"));
    const cartDataLengt = cartData ? cartData?.length : 0;
    setcdl(cartDataLengt);
  };

  useEffect(() => {
    getcartlen();
  }, []);

  const handleCategory = (category) => {
    navigate(`/Filter/${category}`);
    SetIsDrawerOpen(false);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => gotoprofile()}>
        <AccountBoxIcon sx={{ mr: 2 }} />
        My Profile
      </MenuItem>
      <MenuItem onClick={() => gotoorders()}>
        <TocIcon sx={{ mr: 2 }} />
        My Orders
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <LogoutIcon sx={{ mr: 2 }} />
        Logout
      </MenuItem>
    </Menu>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        {LoginStatus ? (
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            sx={{ mr: 5 }}
          >
            <AccountCircle />
            <Typography sx={{ ml: 1 }}>
              Hello, <br /> {Username !== "" ? Username : "Profile"}
            </Typography>
          </IconButton>
        ) : (
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={() => setopenPopup(true)}
            color="inherit"
            sx={{ mr: 5 }}
          >
            <AccountCircle />
            <Typography sx={{ ml: 1 }}>Login</Typography>
          </IconButton>
        )}
        <IconButton
          size="large"
          edge="end"
          aria-label="Shopping Cart"
          color="inherit"
          sx={{ mr: 5 }}
          onClick={() => gotocart()}
        >
          <Badge
            badgeContent={cartDataLength !== 0 ? cartDataLength : cdl}
            color="error"
          >
            <ShoppingCartIcon />
          </Badge>
          <Typography sx={{ ml: 1 }}>Cart</Typography>
        </IconButton>
      </MenuItem>
    </Menu>
  );
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          color: "#222020",
          backgroundColor: "#fdd835",
          width: "100%", // Default width for larger screens
        }}
      >
        {" "}
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => SetIsDrawerOpen(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => SetIsDrawerOpen(false)}
          >
            <Box
              p={2}
              width={200}
              textAlign="left"
              role="presentation"
              color="inherit"
              sx={{ backgroundColor: "#eeeeee", minHeight: "95vh" }}
            >
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  mb: 1,
                  pl: 1,
                  color: "#bb4430",
                  textAlign: "left",
                }}
              >
                Nut Nutritions
              </Typography>
              <Divider />

              <Box sx={{ display: "flex", mt: 1 }}>
                <Button
                  onClick={() => handleCategory("All")}
                  sx={{
                    color: "black",
                    width: "100%",
                    display: "flex",
                    justifyContent: "left",
                  }}
                  size="large"
                >
                  Best Price
                </Button>
              </Box>
              <Box sx={{ display: "flex", mt: 1 }}>
                <Button
                  onClick={() => handleCategory("All")}
                  sx={{
                    color: "black",
                    width: "100%",
                    display: "flex",
                    justifyContent: "left",
                  }}
                  size="large"
                >
                  Top Offers
                </Button>
              </Box>
              {Data?.map((item) => {
                return (
                  <Box sx={{ display: "flex", mt: 1 }} key={item._id}>
                    <Button
                      onClick={() => handleCategory(item._id)}
                      sx={{
                        color: "black",
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                      }}
                      size="large"
                    >
                      {item.CategoryName}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </Drawer>
          <Box sx={{ mr: 2, cursor: "pointer" }} onClick={() => navigate("/")}>
            Nut Nutritions{" "}
          </Box>
          <Box>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                onChange={(e) => setsearch(e.target.value)}
                value={search}
                placeholder="Search For Products…"
                inputProps={{ "aria-label": "search" }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearchSubmit();
                  }
                }}
              />
            </Search>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* ***Profile*** */}
            {LoginStatus ? (
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => setMenuOpenclose(!menuopenclose)}
                color="inherit"
                sx={{ mr: 5 }}
              >
                <AccountCircle />
                <Typography
                  textAlign={"center"}
                  sx={{ ml: 1, fontSize: "0.9rem" }}
                >
                  Hello, <br /> {Username !== "" ? Username : "Profile"}
                </Typography>
                <Menu
                  sx={{ left: -100, top: -8, width: 200 }}
                  anchorEl={menuopenclose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={menuopenclose}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => gotoprofile()} sx={{ mr: 20 }}>
                    <AccountBoxIcon sx={{ mr: 2 }} />
                    My Profile
                  </MenuItem>
                  <MenuItem onClick={() => gotoorders()} sx={{ mr: 20 }}>
                    <TocIcon sx={{ mr: 2 }} />
                    My Orders
                  </MenuItem>
                  <MenuItem onClick={handleLogout} sx={{ mr: 20 }}>
                    <LogoutIcon sx={{ mr: 2 }} />
                    Logout
                  </MenuItem>
                </Menu>
              </IconButton>
            ) : (
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => setopenPopup(true)}
                color="inherit"
                sx={{ mr: 5 }}
              >
                <AccountCircle />
                <Typography sx={{ ml: 1 }}>Login</Typography>
              </IconButton>
            )}
            <Dialog
              open={openPopup}
              onClose={handleCloseLogin}
            >
              <Box sx={{ width: ["auto", 380], height: ["auto", 405] }}>
                <DialogTitle
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Login Details
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleCloseLogin}
                  >
                    X
                  </span>
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1.5, width: "90%" },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: ["auto", 300],
                    }}
                  >
                    <TextField
                      type="email"
                      name="Email"
                      value={login.Email}
                      onChange={handleLoginChange}
                      variant="standard"
                      label="Email"
                      fullWidth
                    />
                    <TextField
                      type="password"
                      name="Password"
                      value={login.Password}
                      onChange={handleLoginChange}
                      variant="standard"
                      label="Password"
                      fullWidth
                    />
                    <br />
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleLoginSubmit}
                      color="error"
                      sx={{ width: "90%", height: 50, bgcolor: "#bb4430" }}
                    >
                      Login
                    </Button>
                    <br />
                    <Button size="small" onClick={() => handleClick()}>
                      New to Nut Nutritions? Create an Account
                    </Button>
                  </Box>
                </DialogContent>
              </Box>
            </Dialog>
            {/* --signup----         */}
            <Dialog
              open={signup}
              onClose={handleCloseRegister}
              sx={{ mt: -3 }}
            >
              <Box
                sx={{
                  width: ["auto", 500],
                  height: ["auto", 550],
                  bgcolor: "#fff",
                }}
              >
                <DialogTitle
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Create an Account
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleCloseRegister}
                  >
                    X
                  </span>
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1.2, width: "96%" },
                      mt: -2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: ["auto", 450],
                    }}
                  >
                    {!isotp ? (
                      <>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: ["column", "row"],
                      }}
                    >
                      <TextField
                        required
                        sx={{ width: "50%" }}
                        type="text"
                        name="FirstName"
                        value={register.FirstName}
                        onChange={handleChange}
                        variant="standard"
                        label="First Name"
                      ></TextField>
                      <TextField
                        required
                        sx={{ width: "50%" }}
                        type="text"
                        name="LastName"
                        value={register.LastName}
                        onChange={handleChange}
                        variant="standard"
                        label="Last Name"
                      ></TextField>
                    </Box>
                    <TextField
                      required
                      type="email"
                      name="Email"
                      value={register.Email}
                      onChange={handleChange}
                      variant="standard"
                      label="Email"
                      helperText={emailError}
                    ></TextField>
                    <TextField
                      required
                      type="tel"
                      name="Mobile"
                      value={register.Mobile === 0 ? "" : register.Mobile}
                      onChange={handleChange}
                      variant="standard"
                      helperText={mobileError}
                      label="Mobile Number"
                    ></TextField>
                    <TextField
                      required
                      type="password"
                      name="Password"
                      value={register.Password}
                      onChange={handleChange}
                      variant="standard"
                      label="Password"
                    ></TextField>
                    <FormControl required sx={{ width: ["auto", "93%"] }}>
                      <FormLabel sx={{ textAlign: "left", mt: 2 }}>
                        Your Gender
                      </FormLabel>
                      <RadioGroup
                        row
                        sx={{ mr: 1 }}
                        name="Gender"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                    <TextField
                      required
                      type="text"
                      name="Address"
                      value={register.Address}
                      onChange={handleChange}
                      variant="standard"
                      label="Address"
                    ></TextField>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: ["column", "row"],
                      }}
                    >
                      <TextField
                        select
                        required
                        name="State"
                        value={register.State}
                        onChange={handleStateChange}
                        variant="standard"
                        label="State"
                      >
                        {states.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        required
                        name="City"
                        value={register.City}
                        onChange={handleCityChange}
                        variant="standard"
                        label="City"
                        disabled={!selectedState}
                      >
                        {cities.map((city) => (
                          <MenuItem key={city} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        required
                        type="text"
                        name="PinCode"
                        value={register.PinCode}
                        onChange={handleChange}
                        variant="standard"
                        label="Pincode"
                      ></TextField>
                    </Box>
                    <TextField
                      required
                      type="text"
                      name="ReferenceBy"
                      value={register.ReferenceBy}
                      onChange={handleChange}
                      variant="standard"
                      label="Reference ID"
                    ></TextField>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        mt: 1,
                        width: "90%",
                        height: 50,
                        bgcolor: "#bb4430",
                      }}
                      type="button"
                      onClick={VerifyFormAndSendOTP}
                    >
                      Create Account
                    </Button>
                    <br />
                    <Button size="small" onClick={() => handleClick()}>
                      Already a Customer? Login
                    </Button>
                    </>
                    ):(
                      <>
                      <TextField
                      required
                      type="tel"
                      name="otp"
                      value={otp === 0 ? "" : otp}
                      onChange={(e)=>setOtp(e.target.value)}
                      variant="standard"
                      label="Enter OTP"
                    ></TextField>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        mt: 1,
                        width: "90%",
                        height: 50,
                        bgcolor: "#bb4430",
                      }}
                      type="button"
                      onClick={handleSubmit}
                    >
                      Verify OTP
                    </Button>
                    </>
                    )}
                  </Box>
                </DialogContent>
              </Box>
            </Dialog>
            <IconButton
              size="large"
              edge="end"
              aria-label="Shopping Cart"
              color="inherit"
              sx={{ mr: 5 }}
              onClick={() => gotocart()}
            >
              <Badge
                badgeContent={cartDataLength !== 0 ? cartDataLength : cdl}
                color="error"
              >
                <ShoppingCartIcon />
              </Badge>
              <Typography sx={{ ml: 1 }}>Cart</Typography>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <ToastContainer />
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
